<template>
  <div class="container">
    <!-- 产品特点 -->
    <div class="featuresContainer">
      <div class="title">Features</div>
      <div class="content">
        <div>
          Gathall (Gathering all) is a free organizer application that allows
          users to plan various travel/event activities. Additionally,
        </div>
        <div>
          friends/family can discover and join your travel/event plan through
          this app. Users can also record information about
        </div>
        <div>
          flights, trains, hotels, and activities. At the end of the event,
          users can share easily and privately with friends the photos/
        </div>
        <div>videos on this app.</div>
      </div>
    </div>
    <!-- 九宫格 -->
    <div class="bottomContainer">
      <div class="top item">
        <div class="item1">
          <img src="../../assets/Features/features1.png" alt="" />
          <div class="title">Calendar</div>
          <div class="content">
            Provide a visible itinerary/activity between friend’s/family/
            colleagues. The shared calendar helps organize everyone's schedules,
            activities, and appointments in one place that's accessible anytime,
            anywhere.
          </div>
        </div>
        <div class="item1 itemMiddle">
          <img src="../../assets/Features/features2.png" alt="" />
          <div class="title">Photos/videos</div>
          <div class="content">
            All pictures taken during the trip/event can be uploaded and other
            members can download easily and privately.
          </div>
        </div>
        <div class="item1">
          <img src="../../assets/Features/features3.png" alt="" />
          <div class="title">Add Links</div>
          <div class="content">
            Easily include links to share travel tips or other information found
            online.
          </div>
        </div>
      </div>
      <div class="middle item">
        <div class="item1">
          <img src="../../assets/Features/features4.png" alt="" />
          <div class="title">Activity Loop</div>
          <div class="content">
            For activities with fixed dates, they can be set to repeat on
            specific days of the week, eliminating the need to add them
            individually. Useful for regular fitness, arts, entertainment
            activities, fixed children's classes, schedules, etc.
          </div>
        </div>
        <div class="item1 itemMiddle">
          <img src="../../assets/Features/features5.png" alt="" />
          <div class="title">Activity Reminders</div>
          <div class="content">
            Set activity reminders with options for reminders every 30 minutes,
            1 hour, 2 hours, 4 hours, 1 day, etc.
          </div>
        </div>
        <div class="item1">
          <img src="../../assets/Features/features6.png" alt="" />
          <div class="title">Favourites</div>
          <div class="content">
            Save and collect all shared content from friends of interest, making
            it easier to plan visits to those places in the future.
          </div>
        </div>
      </div>
      <div class="bottom item">
        <div class="item1">
          <img src="../../assets/Features/features7.png" alt="" />
          <div class="title">Share</div>
          <div class="content">
            Share real-time location along with scenic spots, delicious food,
            and personal experiences.
          </div>
        </div>
        <div class="item1 itemMiddle">
          <img src="../../assets/Features/features8.png" alt="" />
          <div class="title">Create Groups</div>
          <div class="content">
            Create a group where all friends can join. In the group, members can
            post announcements about upcoming events, create group schedules,
            and record all group activities. Members can join any activity they
            are interested in, review the history of past activities, and relive
            the wonderful memories through the photos/video saved in the group
            album.
          </div>
        </div>
        <div class="item1">
          <img src="../../assets/Features/features9.png" alt="" />
          <div class="title">View Friends' Schedules</div>
          <div class="content">
            Quickly check and join other friends’ travel/event plan, making more
            chance to reach friends/family often.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Features",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.container {
  .featuresContainer {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10%;
    background-color: #22242c;
    color: #fff;

    .title {
      font-size: 60px;
      font-weight: 900;
      margin-bottom: 20px;
    }
    .content {
      div {
        font-size: 18px;
        font-weight: 400;
      }
    }
  }
  .bottomContainer {
    margin-top: 60px;
    .item {
      display: flex;
      justify-content: center;
      .itemMiddle {
        margin: 0 40px;
      }
      .item1 {
        padding: 32px;
        border: 1px solid rgba(206, 207, 213, 0.4);
        border-radius: 12px;
        background-color: rgba(34, 36, 44, 0.62);
        margin-top: 40px;
        height: 20rem;
        width: 22%;
        .title {
          margin: 20px 0;
          color: #3dc4f6;
          font-size: 24px;
          font-weight: 600;
        }
        .content {
          color: #fff;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
