<template>
  <div>
    <div class="container">
      <!-- 顶部导航条 -->
      <div class="topNavigationBar">
        <!-- 左侧logo区域 -->
        <img class="logo" src="@/assets/headers/logo.png" alt="" />
        <!-- 右侧导航条 -->
        <div class="navigationBar">
          <router-link exact to="/">Home</router-link>
          <router-link to="/features">Features</router-link>
          <router-link to="/setting">Setting</router-link>
          <router-link to="/about">Usage</router-link>
          <router-link to="/contact">Contact</router-link>

          <!-- <div
            :class="{ active: activeTab === 'home' }"
            @click="setActiveTab('home')"
          >
            Home
          </div>
          <div
            :class="{ active: activeTab === 'features' }"
            @click="setActiveTab('features')"
          >
            Features
          </div>
          <div
            :class="{ active: activeTab === 'setting' }"
            @click="setActiveTab('setting')"
          >
            Setting
          </div>
          <div
            :class="{ active: activeTab === 'about' }"
            @click="setActiveTab('about')"
          >
            Usage
          </div>
          <div
            :class="{ active: activeTab === 'contact' }"
            @click="setActiveTab('contact')"
          >
            Contact
          </div> -->
        </div>
      </div>
      <!-- 中体部分 -->
      <router-view />
      <!-- <Home v-if="activeTab == 'home'" />
      <Features v-else-if="activeTab == 'features'" />
      <Setting v-else-if="activeTab == 'setting'" />
      <About v-else-if="activeTab == 'about'" />
      <Contact v-else-if="activeTab == 'contact'" /> -->
      <!-- 底部 -->
      <BottomFoot
        :activeTab="activeTab"
        :usageTab="usageTab"
        :contactTab="contactTab"
      />
    </div>
  </div>
</template>
<script>
// import Home from "@/views/Home/index";
// import Features from "@/views/Features/index";
// import Setting from "@/views/Setting/index";
// import Contact from "@/views/Contact/index";
// import About from "@/views/About/index.vue";
import BottomFoot from "@/components/BottomFoot/BottomFoot";
export default {
  data() {
    return {
      activeTab: "home",
    };
  },
  components: {
    // Home,
    // Features,
    // Setting,
    // Contact,
    // About,
    BottomFoot,
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    usageTab() {
      this.activeTab = "about";
    },
    contactTab() {
      this.activeTab = "contact";
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  padding-top: 2vh;
  .topNavigationBar {
    display: flex;
    padding-left: 10vw;
    padding-bottom: 10vh;
    height: 6vh;
    .logo {
      display: flex;
      margin: auto 0;
      height: 50px;
    }
    .navigationBar {
      display: flex;
      padding-left: 10vw;
      a {
        text-decoration: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-right: 90px;
        color: #fff;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      a:last-child {
        margin-right: 0;
      }
      a.active {
        color: #3dc4f6;
        border-bottom: 5px solid #3dc4f6;
      }
      // .getApp {
      //   color: #fff;
      //   padding: 14px 30px;
      //   background-color: #424753;
      //   border-radius: 7px;
      // }
    }
  }
}
</style>
