<template>
  <div id="app" style="background-color: #424753">
    <Index />
  </div>
</template>

<script>
import Index from "@/views/Index/Index";
export default {
  name: "App",
  components: {
    Index,
  },
};
</script>
<style>
html,
body {
  margin: 0;
  padding: 0;
}
</style>
