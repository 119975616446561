<template>
  <div class="AboutContainer">
    <!-- 标题 -->
    <div class="title">
      After all settings, find your way to organize you with your family,
      friends: Exhibition, Biz planning, Seminar, Event, Group trip, Wedding,
      Extracurricular activities…
    </div>
    <!-- 副标题 -->
    <div class="subheading">Video for</div>
    <!-- 视频 -->
    <div class="videoContainer">
      <!-- 1 -->
      <div class="videoItem">
        <!-- 左 -->
        <div class="videoLeft">
          <span>
            1) Set a trip/party yourself or invite your tripmate to share the
            program, upload your event permission, boarding/train pass etc.
            Share your photos/video after your trip/party.
          </span>
        </div>
        <!-- 右 -->
        <div class="videoRight">
          <img
            src="@/assets/About/video1.png"
            alt=""
            @click="showVideoClick(0)"
          />
          <!-- <video width="120" height="216" controls>
            <source src="@/assets/About/video1.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video> -->
        </div>
      </div>

      <!-- 2 -->
      <div class="videoItem">
        <!-- 左 -->
        <div class="videoLeft">
          2) Set some meetings for your business partner/boss for 2 days, she/he
          can follow all information in this program (flight, bus, taxi, address
          of appointment, restaurant …)
        </div>
        <!-- 右 -->
        <div class="videoRight">
          <img
            src="@/assets/About/video2.png"
            alt=""
            @click="showVideoClick(1)"
          />
          <!-- <video width="122" height="216" controls>
            <source src="@/assets/About/video2.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video> -->
        </div>
      </div>

      <!-- 3 -->
      <div class="videoItem">
        <!-- 左 -->
        <div class="videoLeft">
          3) As well as a seminar, everyone can get updating program …
        </div>
        <!-- 右 -->
        <div class="videoRight">
          <img
            src="@/assets/About/video3.png"
            alt=""
            @click="showVideoClick(2)"
          />
          <!-- <video width="143" height="216" controls>
            <source src="@/assets/About/video3.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video> -->
        </div>
      </div>

      <!-- 4 -->
      <div class="videoItem">
        <!-- 左 -->
        <div class="videoLeft">
          4) You/your child set the school schedule, as parents can get school
          information …. Or classmate use the same program with all parents, and
          the head teacher can update the activities every day.
        </div>
        <!-- 右 -->
        <div class="videoRight">
          <img
            src="@/assets/About/video4.png"
            alt=""
            @click="showVideoClick(3)"
          />

          <!-- <video width="143" height="216" controls>
            <source src="@/assets/About/video4.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video> -->
        </div>
      </div>

      <!-- 5 -->
      <div class="videoItem">
        <!-- 左 -->
        <div class="videoLeft">5) A simple event like wine tasting…</div>
        <!-- 右 -->
        <div class="videoRight">
          <img
            src="@/assets/About/video5.png"
            alt=""
            @click="showVideoClick(4)"
          />
          <!-- <video width="143" height="216" controls>
            <source src="@/assets/About/video5.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video> -->
        </div>
      </div>
    </div>
    <div v-if="showVideo" class="showVideoContainer">
      <div class="showVideo">
        <div
          @click="showVideoClick"
          style="color: #fff; font-size: 26px; cursor: pointer"
        >
          X
        </div>
        <video width="429" height="500" controls v-if="videoIndex == 0">
          <source src="@/assets/About/video1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video width="429" height="500" controls v-if="videoIndex == 1">
          <source src="@/assets/About/video2.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video width="429" height="500" controls v-if="videoIndex == 2">
          <source src="@/assets/About/video3.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video width="429" height="500" controls v-if="videoIndex == 3">
          <source src="@/assets/About/video4.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video width="429" height="500" controls v-if="videoIndex == 4">
          <source src="@/assets/About/video5.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "About",
  data() {
    return {
      showVideo: false,
      videoIndex: 0,
      videoList: [
        {
          id: 1,
          url: "@/assets/About/video1.mp4",
        },
        {
          id: 2,
          url: "@/assets/About/video2.mp4",
        },
        {
          id: 3,
          url: "@/assets/About/video3.mp4",
        },
        {
          id: 4,
          url: "@/assets/About/video4.mp4",
        },
        {
          id: 5,
          url: "@/assets/About/video5.mp4",
        },
      ],
    };
  },
  methods: {
    showVideoClick(index) {
      this.videoIndex = index;
      this.showVideo = !this.showVideo;
    },
  },
};
</script>
<style scoped lang="less">
.AboutContainer {
  .title {
    margin-top: 83px;
    text-align: center;
    color: #3dc4f6;
    font-weight: 600;
    font-size: 32px;
  }
  .subheading {
    margin-top: 100px;
    color: #3dc4f6;
    font-size: 32px;
    font-weight: 600;
    text-align: center;
  }
  .videoContainer {
    .videoItem {
      display: flex;
      margin-bottom: 42px;
      height: 216px;

      .videoLeft {
        width: 50%;
        color: #a0abb6;
        font-size: 16px;
        font-weight: 600;
        width: 477px;
        margin: auto;
      }
      .videoRight {
        width: 50%;
        display: flex;
        justify-content: center;
        img {
          cursor: pointer;
        }
      }
    }
  }
  .showVideoContainer {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .showVideo {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
