<template>
  <div class="container">
    <!-- 简介 -->
    <div class="synopsis">
      <!-- 左边 -->
      <div class="leftSynopsis">
        <div>
          <!-- logo -->
          <div>
            <img
              style="width: 80%"
              src="@/assets/headers/synopsisLogo1.png"
              alt=""
            />
          </div>

          <div class="bottomContainer">
            <div class="btnContainer">
              <div class="btnTop">
                <!-- <img src="@/assets/Home/apple.png" alt="" style="height: 50%" /> -->

                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=uni.UNI5A12700&pcampaignid=web_share"
                >
                  <div
                    class="btnGoogle"
                    style="
                      box-shadow: 2px 15px 50px 5px rgba(255, 255, 255, 0.3);
                    "
                  >
                    <img
                      src="@/assets/headers/GoogleImg.png"
                      alt=""
                      style="height: 2.5vh; width: 2.5vh"
                    />
                    <span>Google play</span>
                  </div>
                </a>
                <span style="width: 1vw"></span>
                <a
                  target="_blank"
                  href="https://apps.apple.com/cn/app/gathall/id6451226692?l=en-GB"
                >
                  <div
                    class="btnApple"
                    style="
                      box-shadow: 2px 15px 50px 5px rgba(255, 255, 255, 0.3);
                    "
                  >
                    <img
                      src="@/assets/headers/AppleImg.png"
                      alt=""
                      style="height: 2.5vh; width: 2.5vh"
                    />
                    <span>Apple store</span>
                  </div>
                </a>
              </div>
              <!-- 按钮 -->
              <!-- <div
                class="imgBtn"
                @click="donationClick"
                style="box-shadow: 3px 15px 40px 5px rgba(255, 255, 255, 0.4)"
              >
                <img
                  src="@/assets/headers/thank.png"
                  alt=""
                  style="margin-right: 1vw; height: 2.5vh; width: 2.5vh"
                />
                Thank you for your contribution!
              </div> -->
              <div
                class="imgBtn"
                @click="donationClick"
                style="box-shadow: 3px 15px 40px 5px rgba(255, 255, 255, 0.4)"
              >
                <img
                  src="@/assets/headers/thank.png"
                  alt=""
                  style="margin-right: 1vw; height: 2.5vh; width: 2.5vh"
                />
                Get the app
              </div>
            </div>
          </div>
          <!-- services Category -->
          <!-- <div class="service">
            <img
              style="display: flex; justify-content: center"
              src="@/assets/headers/service.png"
              alt=""
            />
          </div> -->
        </div>
      </div>
      <!-- 右边 -->
      <div class="rightSynopsis">
        <!-- 图片 -->
        <div class="imgContainer">
          <img src="@/assets/headers/Tourism.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopHeader",
  data() {
    return {
      starIndex: 1,
    };
  },
  mounted() {
    this.updateStarIndex();
  },
  methods: {
    donationClick() {
      window.open(
        "https://uat-houshuang.oss-cn-shanghai.aliyuncs.com/apk/gathall.apk"
      );
    },
    updateStarIndex() {
      setInterval(() => {
        if (this.starIndex == 1) {
          this.starIndex = 2;
        } else if (this.starIndex == 2) {
          this.starIndex = 3;
        } else if (this.starIndex == 3) {
          this.starIndex = 1;
        }
      }, 500);
    },
  },
};
</script>

<style lang="less" scoped>
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
.synopsis {
  display: flex;
  .leftSynopsis {
    display: flex;
    justify-content: center;
    width: 50%;
    .bottomContainer {
      margin-top: 50px;
      display: flex;
      justify-content: center;
      margin-left: -8vw;
      .btnContainer {
        width: 50%;
        margin-top: 5vh;
        .btnTop {
          display: flex;
          justify-content: center;
          .btnGoogle {
            margin-left: 1vw;
            cursor: pointer;
          }
          .btnApple {
            margin-right: 1vw;
            cursor: pointer;
          }
          .btnGoogle,
          .btnApple {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 11vw;
            height: 5vh;
            flex-shrink: 0;
            border-radius: 48px;
            background: #3dc4f6;

            img {
              width: 1.5vw;
            }
            span {
              margin-left: 1vw;
              color: #424753;
              text-align: center;
              font-family: Poppins;
              font-size: 1vw;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }
          }
        }
        .imgBtn {
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 5vh;
          width: 23vw;
          height: 5vh;
          flex-shrink: 0;
          border-radius: 48px;
          background: rgba(163, 213, 232, 1);
          color: #424753;
          font-family: Poppins;
          font-size: 1vw;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          cursor: pointer;
        }
      }
    }
    .service {
      display: flex;
      justify-content: center;
      height: 15vh;
      margin-top: 10vh;
      margin-left: 2vw;
    }
  }
  .rightSynopsis {
    width: 50%;
    .imgContainer {
      margin-top: -13vh;
      margin-bottom: -24vh;
      margin-left: -7vw;
      img {
        width: 100%;
      }
    }
  }
}
</style>
