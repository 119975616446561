<template>
  <div>
    <div v-if="pageIndex == 1">
      <!-- learn more about us and review -->
      <div class="review">
        <div class="reviewTitle">Learn more about us</div>
        <div class="reviewText">
          Simplify workflows, gathering all your activities on this application
        </div>
        <div
          class="tileBtn"
          style="margin-top: 73px; cursor: pointer"
          @click="() => (this.pageIndex = 2)"
        >
          How to join in
        </div>
        <div
          class="tileBtn"
          @click="() => (this.pageIndex = 3)"
          style="cursor: pointer"
        >
          How to change your account name
        </div>
        <div
          class="tileBtn"
          @click="() => (this.pageIndex = 4)"
          style="cursor: pointer"
        >
          How to find/add your friend
        </div>
        <div
          class="tileBtn"
          @click="() => (this.pageIndex = 5)"
          style="cursor: pointer"
        >
          How to connect each other
        </div>
      </div>
    </div>

    <div v-else-if="pageIndex == 2">
      <div
        style="
          display: flex;
          justify-content: space-around;
          margin-top: 80px;
          align-items: center;
          margin-bottom: 60px;
        "
      >
        <!-- 返回按钮 -->
        <div class="returnBtnImg" @click="returnClick">
          <img
            style="width: 50px; height: 50px"
            src="@/assets/About/returnBtnImg.png"
            alt=""
          />
        </div>
        <div class="title">How to join in</div>
        <div class="returnBtnImg" @click="nextClick">
          <img
            style="width: 50px; height: 50px"
            src="@/assets/About/nextBtnImg.png"
            alt=""
          />
        </div>
      </div>
      <!-- 流程 -->
      <div class="flow">
        <div class="flowItem">
          <!-- 左 -->
          <div class="flowLeft" style="display: flex; justify-content: center">
            <img
              style="width: 40%"
              src="@/assets/About/CreateAccountImg.png"
              alt=""
            />
          </div>
          <!-- 右 -->
          <div class="flowRight">
            <div class="flowRIghtTitleContainer">
              <img
                src="@/assets/About/CreateAccountTitleImg.png"
                style="width: 40px; height: 40px"
                alt=""
              />
              <div class="flowRIghtTitle">Create account</div>
            </div>
            <div class="flowRightText">
              <div>Use your personal email to create account</div>
              <div>each connection has to put your email and password</div>
              <div>or login with apple or google</div>
              <div>(easy access, reconnection quick)</div>
            </div>
          </div>
        </div>
        <div
          style="text-align: center; margin-top: -300px; margin-bottom: -150px"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="370"
            height="400"
            viewBox="0 0 370 400"
            fill="none"
          >
            <path
              d="M368 1C363.083 55.8602 327.958 174.443 226.798 209.891C159.827 223.078 21.1079 279.363 2 399"
              stroke="#3DC4F6"
              stroke-width="2"
            />
          </svg>
        </div>
        <!-- <div>按钮</div> -->
        <div class="flowItem">
          <!-- 左 -->
          <div class="flowLeft">
            <div class="flowRIghtTitleContainer">
              <img
                src="@/assets/About/verifyCodeTitleImg.png"
                style="width: 40px; height: 40px"
                alt=""
              />
              <div class="flowRIghtTitle">Verify code</div>
            </div>
            <div class="flowRightText">
              An email verify code will send to your email automatically, and
              please put in your code
            </div>
          </div>
          <!-- 右 -->
          <div class="flowRight" style="display: flex; justify-content: center">
            <img
              style="width: 40%"
              src="@/assets/About/verifyCodeImg.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="pageIndex == 3">
      <div
        style="
          display: flex;
          justify-content: space-around;
          margin-top: 80px;
          align-items: center;
          margin-bottom: 60px;
        "
      >
        <!-- 返回按钮 -->
        <div class="returnBtnImg" @click="returnClick">
          <img
            style="width: 50px; height: 50px"
            src="@/assets/About/returnBtnImg.png"
            alt=""
          />
        </div>
        <div class="title">How to change your account name</div>
        <div class="returnBtnImg" @click="nextClick">
          <img
            style="width: 50px; height: 50px"
            src="@/assets/About/nextBtnImg.png"
            alt=""
          />
        </div>
      </div>
      <!-- 流程 -->
      <div class="flow">
        <div class="flowItem">
          <!-- 左 -->
          <div class="flowLeft" style="display: flex; justify-content: center">
            <img
              style="width: 40%"
              src="@/assets/About/settingsImg.png"
              alt=""
            />
          </div>
          <!-- 右 -->
          <div class="flowRight">
            <div class="flowRIghtTitleContainer">
              <img
                src="@/assets/About/settingsTitleImg.png"
                style="width: 40px; height: 40px"
                alt=""
              />
              <div class="flowRIghtTitle">SETTINGS</div>
            </div>
            <div class="flowRightText">
              Switch to the
              <span style="color: #3dc4f6">“SETTINGS”</span> page and click
              “Account”
            </div>
          </div>
        </div>
        <div
          style="text-align: center; margin-top: -400px; margin-bottom: -150px"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="370"
            height="400"
            viewBox="0 0 370 400"
            fill="none"
          >
            <path
              d="M368 1C363.083 55.8602 327.958 174.443 226.798 209.891C159.827 223.078 21.1079 279.363 2 399"
              stroke="#3DC4F6"
              stroke-width="2"
            />
          </svg>
        </div>
        <div class="flowItem">
          <!-- 左 -->
          <div class="flowLeft">
            <div class="flowRIghtTitleContainer">
              <img
                src="@/assets/About/SignUpTitleImg.png"
                style="width: 40px; height: 40px"
                alt=""
              />
              <div class="flowRIghtTitle">Account</div>
            </div>
            <div class="flowRightText">
              Click “Change Nickname” to change your account
            </div>
          </div>
          <!-- 右 -->
          <div class="flowRight" style="display: flex; justify-content: center">
            <img
              style="width: 40%"
              src="@/assets/About/AccountImg.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="pageIndex == 4">
      <div
        style="
          display: flex;
          justify-content: space-around;
          margin-top: 80px;
          align-items: center;
          margin-bottom: 60px;
        "
      >
        <!-- 返回按钮 -->
        <div class="returnBtnImg" @click="returnClick">
          <img
            style="width: 50px; height: 50px"
            src="@/assets/About/returnBtnImg.png"
            alt=""
          />
        </div>
        <div class="title">How to find/add your friend</div>
        <div class="returnBtnImg" @click="nextClick">
          <img
            style="width: 50px; height: 50px"
            src="@/assets/About/nextBtnImg.png"
            alt=""
          />
        </div>
      </div>

      <!-- 流程 -->
      <div class="flow">
        <div class="flowItem">
          <!-- 左 -->
          <div class="flowLeft" style="display: flex; justify-content: center">
            <img
              style="width: 40%"
              src="@/assets/About/networkImg.png"
              alt=""
            />
          </div>
          <!-- 右 -->
          <div class="flowRight">
            <div class="flowRIghtTitleContainer">
              <img
                src="@/assets/About/networkTitleImg.png"
                style="width: 40px; height: 40px"
                alt=""
              />
              <div class="flowRIghtTitle">NETWORK</div>
            </div>
            <div class="flowRightText">
              Switch to the
              <span style="color: #3dc4f6">“Connections”</span> page and click“
              <img src="@/assets/About/plus.png" alt="" style="width: 5%" />
              ”
            </div>
          </div>
        </div>
        <div
          style="text-align: center; margin-top: -400px; margin-bottom: -150px"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="370"
            height="400"
            viewBox="0 0 370 400"
            fill="none"
          >
            <path
              d="M368 1C363.083 55.8602 327.958 174.443 226.798 209.891C159.827 223.078 21.1079 279.363 2 399"
              stroke="#3DC4F6"
              stroke-width="2"
            />
          </svg>
        </div>
        <div class="flowItem">
          <!-- 左 -->
          <div class="flowLeft">
            <div class="flowRIghtTitleContainer">
              <img
                src="@/assets/About/CreateAccountTitleImg.png"
                style="width: 40px; height: 40px"
                alt=""
              />
              <div class="flowRIghtTitle">Friends</div>
            </div>
            <div class="flowRightText">
              Add your friends by email or user ID
            </div>
          </div>
          <!-- 右 -->
          <div class="flowRight" style="display: flex; justify-content: center">
            <img
              style="width: 40%"
              src="@/assets/About/friendsImg.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="pageIndex == 5">
      <div
        style="
          display: flex;
          justify-content: space-around;
          margin-top: 80px;
          align-items: center;
          margin-bottom: 60px;
        "
      >
        <!-- 返回按钮 -->
        <div class="returnBtnImg" @click="returnClick">
          <img
            style="width: 50px; height: 50px"
            src="@/assets/About/returnBtnImg.png"
            alt=""
          />
        </div>
        <div class="title">How to connect each other</div>
        <div class="returnBtnImg" @click="() => (this.pageIndex = 1)">
          <img
            style="width: 50px; height: 50px"
            src="@/assets/About/nextBtnImg.png"
            alt=""
          />
        </div>
      </div>

      <!-- 流程 -->
      <div class="flow">
        <div class="flowItem">
          <!-- 左 -->
          <div class="flowLeft" style="display: flex; justify-content: center">
            <img
              style="width: 40%"
              src="@/assets/About/settingsImg.png"
              alt=""
            />
          </div>
          <!-- 右 -->
          <div class="flowRight">
            <div class="flowRIghtTitleContainer">
              <img
                src="@/assets/About/settingsTitleImg.png"
                style="width: 40px; height: 40px"
                alt=""
              />
              <div class="flowRIghtTitle">SETTINGS</div>
            </div>
            <div class="flowRightText">
              Switch to the
              <span style="color: #3dc4f6">“SETTINGS”</span> page and click“
              <img src="@/assets/About/bell.png" alt="" />
              ”
            </div>
          </div>
        </div>
        <div
          style="text-align: center; margin-top: -400px; margin-bottom: -150px"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="370"
            height="400"
            viewBox="0 0 370 400"
            fill="none"
          >
            <path
              d="M368 1C363.083 55.8602 327.958 174.443 226.798 209.891C159.827 223.078 21.1079 279.363 2 399"
              stroke="#3DC4F6"
              stroke-width="2"
            />
          </svg>
        </div>
        <div class="flowItem">
          <!-- 左 -->
          <div class="flowLeft">
            <div class="flowRIghtTitleContainer">
              <img
                src="@/assets/About/notificationsBtnImg.png"
                style="width: 40px; height: 40px"
                alt=""
              />
              <div class="flowRIghtTitle">NOTIFICATIONS</div>
            </div>
            <div class="flowRightText">
              confirm your friend’s request, then, your friend must also confirm
              your request
            </div>
          </div>
          <!-- 右 -->
          <div class="flowRight" style="display: flex; justify-content: center">
            <img
              style="width: 40%"
              src="@/assets/About/notificationsImg.png"
              alt=""
            />
          </div>
        </div>
        <div style="text-align: center; margin-top: -250px">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="368"
            height="400"
            viewBox="0 0 368 400"
            fill="none"
          >
            <path
              d="M0.999992 1C5.91746 55.8602 41.0422 174.443 142.202 209.891C209.173 223.078 347.892 279.363 367 399"
              stroke="#3DC4F6"
              stroke-width="2"
            />
          </svg>
        </div>
        <div class="flowItem">
          <!-- 左 -->
          <div class="flowLeft" style="display: flex; justify-content: center">
            <img
              style="width: 40%"
              src="@/assets/About/RequestImg.png"
              alt=""
            />
          </div>
          <!-- 右 -->
          <div class="flowRight">
            <div class="flowRIghtTitleContainer">
              <img
                src="@/assets/About/verifyCodeTitleImg.png"
                style="width: 40px; height: 40px"
                alt=""
              />
              <div class="flowRIghtTitle">Request</div>
            </div>
            <div class="flowRightText">
              Click “√” to agree, click “×” to decline
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Setting",
  data() {
    return {
      pageIndex: 1,
    };
  },
  methods: {
    returnClick() {
      this.pageIndex -= 1;
    },
    nextClick() {
      this.pageIndex += 1;
    },
  },
};
</script>
<style lang="less" scoped>
.review {
  margin-top: 40px;
  margin-bottom: 100px;
  .reviewTitle {
    color: #3dc4f6;
    text-align: center;
    font-family: Poppins;
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .reviewText {
    color: #a0abb6;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.text {
  display: flex;
  margin-bottom: 100px;
  .textLeft {
    margin: auto;
    width: 50%;
    .textTitle {
      margin-bottom: 13px;
      color: #3dc4f6;
      font-family: Poppins;
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .useFlow {
      margin-bottom: 10px;
      color: #798999;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .button {
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      width: 166px;
      height: 59px;
      line-height: 59px;
      flex-shrink: 0;
      background-color: #fd9125;
    }
  }
  .textRight {
    margin: auto;
    width: 50%;
    .textTitle {
      margin-bottom: 13px;
      color: #3dc4f6;
      font-family: Poppins;
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .useFlow {
      margin-bottom: 10px;
      color: #798999;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .button {
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      width: 166px;
      height: 59px;
      line-height: 59px;
      flex-shrink: 0;
      background-color: #fd9125;
    }
  }
}
.returnBtnImg {
  display: block;
  cursor: pointer;
  flex: 1;
  // margin-top: 0px;
  margin-left: 27px;
  width: 50px;
  height: 50px;
}
.title {
  flex: 5;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  color: #00b0ff;
  text-align: center;
  font-family: Poppins;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
}
.flow {
  .flowItem {
    display: flex;
    justify-content: space-around;
    margin-bottom: 150px;
    .flowLeft {
      width: 50%;
      margin: auto;

      .flowRIghtTitleContainer {
        display: flex;
        color: #3dc4f6;
        font-family: Poppins;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        .flowRIghtTitle {
          margin-left: 20px;
        }
      }
      .flowRightText {
        margin-left: 60px;
        color: #fff;
        font-family: Poppins;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
    .flowRight {
      width: 50%;
      // margin: auto;
      .flowRIghtTitleContainer {
        margin-bottom: 20px;
        display: flex;
        color: #3dc4f6;
        font-family: Poppins;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        .flowRIghtTitle {
          margin-left: 38px;
        }
      }
      .flowRightText {
        margin-left: 85px;
        color: #fff;
        font-family: Poppins;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}
.tileBtn {
  text-align: center;
  margin-top: 29px;
  color: #00b0ff;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
</style>
