<template>
  <div class="BottomFootContainer">
    <div class="content">
      <div class="item">
        <div class="title" style="display: flex">
          Made with
          <svg
            style="display: block"
            t="1708412494435"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="6245"
            width="25"
            height="25"
          >
            <path
              d="M512 901.746939c-13.583673 0-26.122449-4.179592-37.093878-13.061225-8.881633-7.314286-225.697959-175.020408-312.424489-311.379592C133.746939 532.37551 94.040816 471.24898 94.040816 384.522449c0-144.718367 108.146939-262.269388 240.326531-262.269388 67.395918 0 131.657143 30.82449 177.632653 84.636735 45.453061-54.334694 109.191837-84.636735 177.110204-84.636735 132.702041 0 240.326531 117.55102 240.326531 262.269388 0 85.159184-37.093878 143.673469-67.395919 191.216327l-1.044898 1.567346c-86.726531 136.359184-303.542857 304.587755-312.424489 311.379592-10.44898 8.359184-22.987755 13.061224-36.571429 13.061225z"
              fill="#E5404F"
              p-id="6246"
            ></path>
          </svg>
          ️
        </div>
        <div class="textContent">
          <!-- <img src="@/assets/BottomFoot/phoneImg.png" alt="" /> -->
          <span class="text">©️ 2023 Gottha Itd</span>
        </div>
      </div>
      <div class="item">
        <div class="title">Contact Us</div>
        <div class="textContent">
          <div style="display: flex">
            <img src="@/assets/BottomFoot/gmailImg.png" alt="" />
            <span style="margin-left: 20px" class="text">info@gathall.com</span>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="title">NEWS LETTER</div>
        <div class="textContent">
          <span class="text">
            Join our mailing list to stay in the loop with our newest.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomFoot",
  props: {
    // 在子组件中声明一个接收函数的props
    usageTab: {
      type: Function,
      required: true,
    },
    contactTab: {
      type: Function,
      required: true,
    },
    activeTab: {
      type: String,
      required: true,
    },
  },
  methods: {
    redirectToRoute() {
      this.$router.push("/Usage");
    },
  },
};
</script>

<style lang="less" scoped>
.BottomFootContainer {
  background-color: #424753;
  padding-bottom: 100px;
  border-radius: 40px 40px 0px 0px;
  // margin-top: 200px;
  .content {
    display: flex;
    justify-content: space-around;
    .item {
      margin-top: 100px;
      .title {
        margin-bottom: 24px;
        color: #fff;
        font-feature-settings: "clig" off, "liga" off;
        font-family: Poppins;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px; /* 104% */
      }
      .textContent {
        margin-bottom: 24px;

        .text {
          cursor: pointer;
          color: #fff;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .textContentInput {
        margin-bottom: 24px;
        display: flex;
        .input {
          width: 317px;
          height: 46px;
          flex-shrink: 0;
          // border-radius: 4px;
          background: var(--White, #fff);
          border: none;
        }
        input:focus {
          outline: none;
        }
        .button {
          width: 52px;
          height: 48px;
          background-color: #3dc4f6;
          display: block;
          border: none;
        }
      }
      .textContent:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
