import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home/index";
import Features from "@/views/Features/index";
import Setting from "@/views/Setting/index";
import Contact from "@/views/Contact/index";
import About from "@/views/About/index.vue";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/features",
    component: Features,
  },
  {
    path: "/setting",
    component: Setting,
  },
  {
    path: "/contact",
    component: Contact,
  },
  {
    path: "/about",
    component: About,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  linkActiveClass: "active",
});

export default router;
