<template>
  <div class="contactContainer">
    <!-- 标题 -->
    <div>
      <div class="contactContainerTitle">Contact Us</div>
      <div class="contactContainerText">
        Any question or remarks? Just write us a message!
      </div>
    </div>

    <!-- please enter -->

    <!-- 卡片 -->
    <div class="cardContainer">
      <!-- 左侧 -->
      <!-- <div class="cardLeft">
        <div class="cardTitle">Contact Information</div>
        <div class="cardText">Say something to start a live chat!</div>
        <div class="gmail">
          <img src="@/assets/BottomFoot/gmailImg.png" alt="" />
          <div>info@gathall.com</div>
        </div>

        <div class="cardLeftBackground">
          <img src="@/assets/Contact/cardLeftBackground.png" alt="" />
        </div>
      </div> -->
      <!-- 右侧 -->
      <div class="cardRight">
        <form>
          <div class="cardRightInputContainer">
            <div class="cardRightName">
              <div>Email</div>
              <div>Phone Number</div>
            </div>
            <div
              class="cardRightInput"
              style="display: flex; justify-content: "
            >
              <el-input
                style="width: 45%; margin-right: 5%"
                v-model="data.email"
                type="text"
                name="email"
                placeholder="please enter Email"
              />
              <el-input
                style="width: 45%"
                v-model="data.phone"
                type="text"
                name="phone"
                placeholder="please enter Phone Number"
              />
            </div>
          </div>
          <div class="cardRightInputContainer">
            <div class="cardRightName">
              <div>Message</div>
            </div>
            <div class="cardRightInputLast">
              <el-input
                style="width: 95%"
                v-model="data.message"
                type="textarea"
                name="message"
                placeholder="Write your message.."
                :rows="5"
              />
            </div>
          </div>
          <div class="cardRightBtnContainer">
            <button class="cardRightBtn" @click="submitForm">
              Send Message
            </button>
          </div>
        </form>

        <div class="cardRightBackgroundContainer">
          <img src="@/assets/Contact/cardRightBackground.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $axios from "axios";
export default {
  data() {
    return {
      data: {
        email: "",
        phone: "",
        message: "",
      },
    };
  },
  methods: {
    async submitForm(event) {
      event.preventDefault();
      // var data = new FormData(event.target);
      fetch("https://formspree.io/f/manwekqy", {
        method: "POST",
        body: JSON.stringify(this.data),
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            this.$set(this.data, "email", "");
            this.$set(this.data, "phone", "");
            this.$set(this.data, "message", "");
            this.$message({
              showClose: true,
              message: "Thanks for your submission!",
              type: "success",
            });
          } else {
            this.$message({
              showClose: true,
              message: "Oops! There was a problem submitting your form",
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.$message({
            showClose: true,
            message: "Oops! There was a problem submitting your form",
            type: "error",
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.contactContainer {
  margin-top: 70px;
  .contactContainerTitle {
    color: #3dc4f6;
    text-align: center;
    font-family: Poppins;
    font-size: 46px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .contactContainerText {
    margin-top: 14px;
    color: #a0abb6;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .cardContainer {
    background-color: #fff;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .cardLeft {
      margin-left: 17px;
      width: 38%;
      width: 543px;
      height: 577px;
      flex-shrink: 0;
      background-color: #424753;
      border-radius: 10px;
      //   display: grid;
      //   grid-template-rows: 1fr auto; /* 最后一行占据剩余空间 */
      .cardTitle {
        color: #fff;
        font-family: Poppins;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 40px 0 6px 55px;
      }
      .cardText {
        margin-left: 55px;
        color: #c9c9c9;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .phone,
      .gmail,
      .address {
        display: flex;
        margin-top: 50px;
        img {
          margin-left: 55px;
        }
        div {
          color: #fff;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-left: 25px;
        }
      }
      .phone,
      .first {
        margin-top: 100px;
      }
      .cardLeftBackground {
        position: relative;
        bottom: -60px;
        left: 300px;
      }
    }
    .cardRight {
      width: 58%;
      margin-left: 50px;
      .cardRightInputContainer {
        margin-top: 30px;
        .cardRightName {
          display: flex;
          color: #000;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 166.667% */
          div {
            width: 50%;
          }
        }
        .cardRightInput {
          margin-top: 4px;
          // display: flex;
          input {
            height: 35px;
            width: 30%;
            margin-right: 25px;
            border: none;
            border-bottom: 1px solid #000;
            outline: none;
          }
        }
        .cardRightInputLast input {
          margin-top: 4px;
          height: 35px;
          border: none;
          border-bottom: 1px solid #000;
          width: 95%;
          outline: none;
        }
      }
      .cardRightBtnContainer {
        cursor: pointer;
        margin-top: 30px;
        margin-left: 300px;
        .cardRightBtn {
          margin-right: 20px;
          cursor: pointer;
          display: inline-flex;
          padding: 15px 48px;
          align-items: flex-start;
          gap: 10px;
          border-radius: 5px;
          border-radius: 5px;
          background: #424753;
          box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
          color: #fff;
          text-align: center;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      .cardRightBackgroundContainer {
        margin-left: 120px;
      }
    }
  }
}
</style>
